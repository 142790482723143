//
// Nav Top CSS
//

.nav-top {
  float: left;
  padding: 0 10px;
  background: $grey-dark;

  ul {
    @include ul-reset;
  }

  li {
    float: left;

    a {
      display: block;
      background: $grey-dark;
      color: $white;
      line-height: 60px;
      padding: 0 15px;
      text-transform: uppercase;
      font-weight: 700;
      text-decoration: none;
      letter-spacing: 2px;

      &:hover {
        background: $grey-mid;
      }

      &:focus-visible {
        outline: 2px dashed $white;
        outline-offset: -3px;
      }
    }
  }

  @media screen and (max-width: $size-S6) {
    display: none;
  }
}