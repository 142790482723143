//
// Burger Icon CSS
//

.burger-icon {
  float: left;
  background: $white;
  height: 60px;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 16px 25px 16px 20px;
  line-height: 28px;
  font-weight: 700;
  cursor: pointer;
  border: none;

  &:focus-visible {
    outline: 2px dashed $red_base;
    outline-offset: -3px;
  }
}

.burger-icon-icon {
  float: left;
  margin-right: 10px;
  pointer-events: none;

  > span {
    display: block;
    height: 2px;
    width: 36px;
    margin: 3px 0;
    background: $grey-dark;
  }
}