//
// Companies from EXT: pv_locator
//

// Main category navigation - restrict to parent page "Wirtschaft"
.pid-6 {

  .frame-type-menu_subpages {
    margin-top: 40px;

    ul {
      @include ul-reset;
      display: flex;
      margin: auto;
      width: 600px;
    }

    li {
      a {
        display: block;
        background: url("../Images/dienstleister.svg") no-repeat top center;
        background-size: 80px 80px;
        padding-top: 90px;
        min-width: 120px;
        text-align: center;

        &.active,
        &:hover {
          background-image: url("../Images/dienstleister_act.svg");
        }
      }

      &:nth-child(2) {
        a {
          background-image: url("../Images/handwerk.svg");

          &.active,
          &:hover {
            background-image: url("../Images/handwerk_act.svg");
          }
        }
      }

      &:nth-child(3) {
        a {
          background-image: url("../Images/einzelhandel.svg");

          &.active,
          &:hover {
            background-image: url("../Images/einzelhandel_act.svg");
          }
        }
      }

      &:nth-child(4) {
        a {
          background-image: url("../Images/essen.svg");

          &.active,
          &:hover {
            background-image: url("../Images/essen_act.svg");
          }
        }
      }

      &:nth-child(5) {
        a {
          background-image: url("../Images/auto.svg");

          &.active,
          &:hover {
            background-image: url("../Images/auto_act.svg");
          }
        }
      }

    }


    @media screen and (max-width: $size-S6) {

      ul {
        width: 325px;
      }

      li {
        a {
          overflow: hidden;
          text-indent: -9999px;
          padding-top: 40px;

          background-size: 50px;
          min-width: 65px;
        }
      }

    }

  }

}


.location-category-filter {
  width: 100%;
  max-width: 800px;
  margin: 40px auto;

  ul {
    @include ul-reset;
    display: none;
  }

  button {
    background: none;
    border: none;
  }

  .location-category-filter-label {
    color: $white;
    width: 100%;
    padding: 6px;
    margin-bottom: 8px;
    background: $grey url("../Images/arrow_down_white.svg") no-repeat scroll 97% center/20px auto;
  }


  @media screen and (min-width: $size-S6) {

    .location-category-filter-label {
      display: none;
    }

    ul {
      display: flex !important;
      flex-flow: row wrap;

      li {
        width: calc(100% / 3);
      }
    }
  }

  @media screen and (min-width: $size-M3) {
    ul {
      display: flex;
      flex-flow: row wrap;

      li {
        width: calc(100% / 4);
      }
    }
  }
}


.location-list {

  ul {
    @include ul-reset;
    display: flex;
    flex-flow: row wrap;
    margin: -20px;
    overflow: hidden;
  }

  li {
    width: calc(100% / 4);
    padding: 20px;
    display: flex;
    flex-flow: column;
  }

  @media screen and (max-width: $size-XL) {
    li {
      width: calc(100% / 3);
    }
  }

  @media screen and (max-width: $size-M3) {
    li {
      width: calc(100% / 2);
    }
  }

  @media screen and (max-width: $size-S4) {
    li {
      width: 100%;
    }
  }

}

.location-image {
  img {
    display: block;
    max-width: none;
    width: 100%;
  }
}

.location-list-info {
  background: $grey-light;
  padding: 10px 15px;
  flex: 1;

  h3 {
    @include font-size(16);
    font-weight: 700;
    margin-bottom: 5px;
  }
}
