//
// CE Hero Image CSS
//

.ce-hero-image {

  img {
    display: block;
    max-width: none;
    width: 100%;
  }

}

main > .ce-hero-image {

  margin: 20px 0;

  .image-container {
    position: relative;

    figcaption {
      position: absolute;
      bottom: 0;
      background: $grey-dark;
      color: $white;

      width: 100%;
      max-width: 900px;
      left: 50%;
      transform: translate(-50%, 0);
      padding: 15px 20px;

      h2 {
        margin-bottom: 0;
        color: $white;
      }
    }


    @media screen and (max-width: $size-S6) {
      figcaption {
        position: relative;
        bottom: auto;
        left: auto;
        transform: translate(0, 0);

        h2 {
        }
      }
    }

  }

}


// start page in header
.header-secondary .ce-hero-image {

  margin: 0;

  img {
    display: block;
  }

  .image-container {
    position: relative;

    figcaption {
      position: absolute;
      top: 50%;
      color: $white;

      width: 100%;
      max-width: $maxPageWidth;
      left: 50%;
      transform: translate(-50%, 0);
      padding: 15px 20px;

      h2 {
        margin-top: 20px;
        @include font-size(100);
        text-transform: uppercase;
        color: $white;
      }

      span {
        position: absolute;
        left: 20px;
        top: 0;
        @include font-size(32);
      }


      @media screen and (max-width: $size-XL) {
        h2 {
          margin-top: 10px;
          @include font-size(60);
        }

        span {
          @include font-size(26);
        }
      }

      @media screen and (max-width: $size-M3) {
        h2 {
          @include font-size(40);
        }

      }

      @media screen and (max-width: $size-XS) {
        top: 40%

      }
    }

  }

}
