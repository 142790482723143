//
// Footer CSS
//

footer {
  background: $grey;
  padding: 20px 0;
  margin-top: 50px;
}

.footer-bottom {
  max-width: $maxPageWidth;
  margin: auto;
  @include clearfix;
  color: $white;
  padding: 0 20px;

  a {
    color: $white;
    text-decoration: none;
  }

  .contact {
    padding: 20px 0 0 0;
    margin-right: 290px;

    @media screen and (max-width: $size-M3) {
      margin-right: 250px;
    }

    @media screen and (max-width: $size-S3) {
      margin: 0;
    }
  }


  .legal-links {
    padding-top: 15px;
    text-transform: uppercase;
  }


}