//
// CE Plugin CSS
//

.ce-plugin {

  padding: 20px 0;

  .ce-inner {
    padding: 0 20px;
  }

  &.layout-2 {
    padding-top: 0;

    > .ce-inner {
      padding: 0;
      margin: 0;
      max-width: none;
    }
  }
}


// start page
.ce-plugin {
  //padding-bottom: 0;
}


// start page news teaser
#c161 {
  background: linear-gradient(#65a2cf, #dbe6f4);

  header {
    h2 {
      color: $white;
    }
  }
}
