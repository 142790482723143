//
// CE Text Media Custom CSS
//

.ce-text-media {

  padding: 20px 0;

  .ce-inner {
    @include clearfix;
    padding: 0 20px;
  }

  .ce-text-media-body {
    display: flex;
  }

  .type-video {
    a {
      display: block;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: url("../Images/youtube-play-button.svg") no-repeat center center;
        background-size: 80px;
        opacity: .7;
      }

      &:hover {
        &::after {
          opacity: 1;
        }
      }
    }
  }

  img {
    display: block;
  }

  &.layout-0 {

    .media-container,
    .bodytext {
      width: 50%;
    }

    .ce-media-position-0,
    .ce-media-position-26 {
      .media-container {
        padding-right: 15px;
      }

      .bodytext {
        padding-left: 15px;
      }
    }

    .ce-media-position-25 {
      .media-container {
        padding-left: 15px;
        order: 2;
      }

      .bodytext {
        padding-right: 15px;
      }
    }
  }

  @media screen and (max-width: $size-L) {

    .ce-text-media-body {
      display: block;
    }

    .media-container {
      width: auto !important;
      padding: 0 !important;
    }

    .bodytext {
      width: auto !important;
      padding: 0;
      margin-top: 20px;
    }
  }

  .video-container {
    position: relative;
    padding-bottom: 56.25%; /* ratio 16x9 */
    height: 0;
    overflow: hidden;
    width: 100%;
    height: auto;
  }

  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }


  // image small
  &.layout-10 {
    .media-container {
      width: 200px;
      flex-shrink: 0;
    }

    .bodytext {
      padding-left: 40px;
      max-width: 600px;
    }

    @media screen and (max-width: $size-M2) {
      .media-container {
        width: 120px;
      }

      .bodytext {
       padding-left: 20px;
      }
    }

    @media screen and (max-width: $size-S) {

      .ce-text-media-body {
        display: block;
      }

      .media-container {
        width: auto;
        margin-bottom: 20px;
      }

      .bodytext {
        padding-left: 0;
      }
    }
  }

  // image small
  &.layout-40 {

    .media-container {
      padding-right: 40px;
    }

    img {
      border: 5px solid black;
    }

    .bodytext {
      flex: 1;
      padding: 20px 30px;
      text-align: center;
      border: 5px solid black;
    }

  }

}


// YouTube Consent
.youtube-consent {
  background: $white;
  max-width: 620px;
  margin: auto;
  padding: 40px;
  position: relative;

  .checkbox {
    position: relative;
    padding-left: 20px;

    input {
      position: absolute;
      left: 0;
      top: 4px;
    }

    label {
      @include font-size(12);
    }
  }

  .btn-group {
    margin-top: 20px;

    button {
      border: none;
      padding: 8px 20px;
      background: #666666;
      color: $white;

      &.btn-yt-confirm {
        background: $red;
        margin-right: 10px;
      }

      &:hover {
        background: lighten(#666666, 10);

        &.btn-yt-confirm {
          background: lighten($red, 10);
        }
      }
    }
  }
}