//
// Nav Main CSS
//

.nav-main {
  display: none;
  position: absolute;
  left: 0;
  top: 60px;
  background: $white;
  width: 402px;
  padding-top: 10px;
  box-shadow: 3px 3px 3px rgba(0,0,0, .5);

  ul {
    @include ul-reset;

    a {
      color: #706f6f;
      display: block;

      &:hover,
      &.active {
        color: $white;
        background: $red_base;
      }

      &:focus-visible {
        outline: 2px dashed $red_base;
        outline-offset: -2px;
      }
    }

    // hide level 2
    ul {
      // hide by display none, so keyboard navigation dont enter this section to step through
      display: none;
      max-height: 0;
      height: auto;
      transition: max-height 0.5s ease-in-out;
      overflow: hidden;


      &.open {
        //display: block;
        max-height: 500px;
      }
    }

    li:hover > a {
      background: $red_base;
    }
  }

  .btn-submenu-control {
    all: unset;
    cursor: pointer;;
    box-sizing: border-box;
    display: block;
    width: 100%;
    position: relative;


    &::after {
      position: absolute;
      right: 10px;
      top: 10px;
      content: "";
      background: url("../Svgs/arrow_white.svg") no-repeat center center;
      background-size: 20px 20px;
      cursor: pointer;
      width: 20px;
      height: 20px;
      transition: all 200ms;
    }

    &:focus-visible,
    &:hover {
      background: $red_base;
      color: $white;
    }

    &[aria-expanded="true"] {

      &::after {
        transform:rotate(90deg);
      }

    }

  }

  // level 1
  > ul {

    > li {
      border-top: 1px solid $white;

      .btn-submenu-control,
      > a {
        display: block;
        @include font-size(18);
        font-weight: 600;
        text-transform: uppercase;
        padding: 8px 10px;
        background: #36679b;
        color: $white;
      }

      &.active > a {
        border-bottom: 1px solid $white;
      }

      &:first-child {
        border-top: none;
        a {
          background: none;
          color: $grey;
        }
      }


      // level 2
      > ul {
        background: #e1e8f0;

        > li {

          &:first-child {
            margin-top: 5px;
          }

          &:last-child {
            margin-bottom: 10px;
          }

          > a {
            font-weight: 600;
            text-transform: uppercase;
            padding: 3px 10px;
            @include font-size(16);
            background: #e1e8f0;

            &:hover,
            &.active {
              background: $red_base;
              color: $white;
            }
          }

        }
      }
    }

  }


  @media screen and (max-width: $size-S) {
    width: 100vw;

    > ul {

      > li {

        > span {
          width: 50px;
        }
      }
    }
  }


  // hide list 2 main nav items on larger screens as they are permanently displayed in top nav
  @media screen and (min-width: $size-S6) {

    > ul {

      > li {

        &:nth-last-child(-n+2) {
          display: none;
        }
      }
    }

  }

}