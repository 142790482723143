//
// Nav Areas CSS
//

.nav-areas-area {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, .7);
  height: 71px;
  z-index: 9;
}

.nav-areas {
  margin: auto;
  width: 800px;

  ul {
    @include ul-reset;
  }

  > ul {

    > li {
      float: left;
      position: relative;
      width: 159px;

      svg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: .8;
      }

      > a {
        display: block;
        position: relative;
        z-index: 2;
        width: 159px;
        height: 71px;
        color: $white;
        font-weight: 600;
        text-decoration: none;
        text-align: center;
        padding: 24px 10px 0 10px;
      }

      &:hover {
        background: $white;
        svg {
          opacity: 1;
        }
      }


      &:nth-child(1) {
        > a {
          padding-top: 14px;
        }
      }

      &:nth-child(2) {
        .nav-areas-btn-shape {
          fill: url(#gradient-citylife);
        }
      }
      &:nth-child(3) {
        > a {

        }
        .nav-areas-btn-shape {
          fill: url(#gradient-tourism);
        }
      }
      &:nth-child(4) {
        .nav-areas-btn-shape {
          fill: url(#gradient-commerce);
        }
      }
      &:nth-child(5) {
        .nav-areas-btn-shape {
          fill: url(#gradient-culture);
        }
      }


      // level 2

      > ul {
        display: none;
        position: absolute;
        top: 71px;
        background: $white;
        padding: 15px 20px;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, .7);

        li {

          a {
            display: block;
            color: $grey;
            padding: 5px 0;
            white-space: nowrap;

            &:hover {
              color: $red;
            }

          }
        }
      }

      &:hover ul {
        display: block;
      }

    }
  }

}


// Test alterantive Buttons ohne SVG
.nav-areas {

  > ul {

    > li {

      svg {
        display: none;
      }

      > a {

      }

      &:hover {
        background: $white;
      }


      &:nth-child(1) {
        > a {
          padding-top: 14px;
          background-image: -webkit-linear-gradient(to right, rgba(226, 10, 34, .9), rgba(192, 31, 51, .9) );
          background-image: -moz-linear-gradient(to right, rgba(226, 10, 34, .9), rgba(192, 31, 51, .9) );
          background-image: -ms-linear-gradient(to right, rgba(226, 10, 34, .9), rgba(192, 31, 51, .9) );
          background-image: -o-linear-gradient(to right, rgba(226, 10, 34, .9), rgba(192, 31, 51, .9) );
          background-image: linear-gradient(to right, rgba(226, 10, 34, .9), rgba(192, 31, 51, .9) );
        }
      }

      &:nth-child(2) {
        > a {
          background-image: -webkit-linear-gradient(to right, rgba(47, 151, 203, .9), rgba(4, 84, 146, .9));
          background-image: -moz-linear-gradient(to right, rgba(47, 151, 203, .9), rgba(4, 84, 146, .9));
          background-image: -ms-linear-gradient(to right, rgba(47, 151, 203, .9), rgba(4, 84, 146, .9));
          background-image: -o-linear-gradient(to right, rgba(47, 151, 203, .9), rgba(4, 84, 146, .9));
          background-image: linear-gradient(to right, rgba(47, 151, 203, .9), rgba(4, 84, 146, .9));
        }
      }
      &:nth-child(3) {
        > a {
          background-image: -webkit-linear-gradient(to right, rgba(152, 197, 103, .9), rgba(51, 153, 102, .9));
          background-image: -moz-linear-gradient(to right, rgba(152, 197, 103, .9), rgba(51, 153, 102, .9));
          background-image: -ms-linear-gradient(to right, rgba(152, 197, 103, .9), rgba(51, 153, 102, .9));
          background-image: -o-linear-gradient(to right, rgba(152, 197, 103, .9), rgba(51, 153, 102, .9));
          background-image: linear-gradient(to right, rgba(152, 197, 103, .9), rgba(51, 153, 102, .9));
        }
      }
      &:nth-child(4) {
        > a {
          background-image: -webkit-linear-gradient(to right, rgba(72, 99, 123, .9), rgba(46, 62, 81, .9));
          background-image: -moz-linear-gradient(to right, rgba(72, 99, 123, .9), rgba(46, 62, 81, .9));
          background-image: -ms-linear-gradient(to right, rgba(72, 99, 123, .9), rgba(46, 62, 81, .9));
          background-image: -o-linear-gradient(to right, rgba(72, 99, 123, .9), rgba(46, 62, 81, .9));
          background-image: linear-gradient(to right, rgba(72, 99, 123, .9), rgba(46, 62, 81, .9));
        }
      }
      &:nth-child(5) {
        > a {
          background-image: -webkit-linear-gradient(to right, rgba(248, 199, 46, .9), rgba(203, 154, 3, .9));
          background-image: -moz-linear-gradient(to right, rgba(248, 199, 46, .9), rgba(203, 154, 3, .9));
          background-image: -ms-linear-gradient(to right, rgba(248, 199, 46, .9), rgba(203, 154, 3, .9));
          background-image: -o-linear-gradient(to right, rgba(248, 199, 46, .9), rgba(203, 154, 3, .9));
          background-image: linear-gradient(to right, rgba(248, 199, 46, .9), rgba(203, 154, 3, .9));
        }
      }


      // level 2

      > ul {

        &::before {
          content: "";
          position: absolute;
          top: -8px;
          left: 74px;
          width: 0;
          height: 0;
          border-bottom: 8px solid $white;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          z-index: 8;
        }

        li {

          a {
            display: block;
            color: $grey;
            padding: 5px 0;
            white-space: nowrap;

            &:hover {
              color: $red;
            }

          }
        }
      }

    }
  }

}



@media screen and (max-width: $size-L2) {

  .nav-areas-area {
    height: 55px;
  }

  .nav-areas {
    margin: auto;
    width: 620px;

    > ul {

      > li {
        float: left;
        position: relative;
        width: 124px;

        > a {
          height: 55px;
          color: $white;
          padding: 19px 10px 0 10px;
          @include font-size(12);
          width: 100%;
        }

        &:nth-child(1) {
          > a {
            padding-top: 10px;
          }
        }
        &:nth-child(3) {
          > a {
            padding-top: 10px;
          }
        }


        // level 2

        > ul {
          top: 55px;
          background: $white;

        }
      }
    }

  }

}


@media screen and (max-width: $size-S6) {

  .nav-areas-area {
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
    background: rgba(0, 0, 0, .7);
    height: auto;
  }


  .nav-areas {
    margin: 0;
    width: auto;

    > ul {

      > li {
        float: none;
        width: auto;
        border-top: 1px solid $white;
        height: 48px;
        overflow: hidden;

        > a {
          height: 48px;
          color: $white;
          padding: 12px 0 !important;
          @include font-size(16);
          width: 100%;
        }

        &:first-child {
          border-top: none;
        }

      }
    }

  }

}
