//
// CE Contact Person CSS
//

.ce-contact-person {

  &:first-of-type {
    padding-top: 20px;
  }

  &:last-of-type {
    padding-bottom: 20px;
  }

  .ce-inner {
    @include clearfix;
    padding: 0 20px;
    margin-bottom: 5px;

    figure {
      float: left;

      @media screen and (max-width: $size-XS) {
        width: 40px;
      }
    }
  }

}

.ce-contact-person-info {
  border-bottom: 1px solid $grey-dark;
  margin-left: 85px;
  @include clearfix;
  color: $grey;

  a {
    color: $grey;
  }

  .col-50 {
    float: left;
    width: 50%;
    padding-bottom: 5px;

    &:last-child {
      padding-left: 15px;
    }
  }

  .ce-contact-person-name,
  .ce-contact-person-phone {
    font-weight: 600;
    @include font-size(18);
  }


  @media screen and (max-width: $size-S4) {
    .col-50 {
      float: none;
      width: auto;
      padding-bottom: 5px;

      &:last-child {
        padding-left: 0;
      }
    }
  }

  @media screen and (max-width: $size-XS) {
    margin-left: 60px;

    .ce-contact-person-name,
    .ce-contact-person-phone {
      @include font-size(16);
    }
  }
}