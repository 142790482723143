//
// search box CSS
//

.search-box-area {
  float: left;
  margin: 0 0 0 10px;
  position: relative;

  .search-icon {
    background: url("../Svgs/magnifier.svg") no-repeat;
    border: none;
    background-size: 34px 34px;
    text-indent: -9999px;
    width: 34px;
    height: 34px;
    cursor: pointer;
    margin-top: 14px;

    @media screen and (max-width: $size-XS) {
      display: none;
    }
  }

  .search-box {
    position: absolute;
    top: 60px;
    left: -271px;
    padding: 10px;
    background: rgba(0, 0 , 0, .2);
    width: 261px;
    display: none;

    //background: url("../Icons/rectangle_white_with_border.png") 0 6px no-repeat;
    fieldset {
      display: flex;
      border: none;
      padding: 0;
      margin: 0;

      .searchbox-sword {
        margin-right: 15px;
        color: $black;
        border: none;
        padding: 4px 5px;
        text-align: left;
        width: 195px;
      }

      .searchbox-submit-btn {
        background: url("../Svgs/arrow_white.svg") no-repeat;
        border: none;
        text-indent: -9999px;
        width: 28px;
        height: 28px;
      }
    }

    @media screen and (max-width: $size-S6) {
      left: -10px;
    }
  }

  .close-btn {
    display: none;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    width: 27px;
    height: 34px;
    border: 1px solid #FFFFFF;

    &::before {
      content: "X";
      color: $white;
      @include font-size(30);
      line-height: 1;
      padding: 0 3px;
    }
  }

}