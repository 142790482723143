//
// CE Image Grid3 CSS
//

.ce-image-grid3 {

  padding: 20px 0;
  @include clearfix;

  .ce-inner {
    padding: 0 20px;
  }

  h3 {
    color: $red;
    font-weight: 600;
    margin: 5px 0 2px 0;
  }

  .images-container {
    display: flex;
    margin: 0 -20px;
  }

  .image-container,
  .video-element {
    width: calc(100% / 3);
    padding: 0 20px;

    img {
      display: block;
    }
  }

  .video-container {
    position: relative;
    padding-bottom: 56.25%; /* ratio 16x9 */
    height: 0;
    overflow: hidden;
    width: 100%;
    height: auto;
  }

  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }

  .more-link {
    margin-top: 20px;

    a {
      display: inline-block;
      padding: 2px 5px;
      border: 1px solid $grey;
      color: $grey;
      text-transform: uppercase;
      text-decoration: none;
    }
  }


  @media screen and (max-width: $size-L4) {

    .images-container {
      margin: 0 -10px;
    }

    .image-container,
    .video-element {
      padding: 0 10px;
    }

  }


  @media screen and (max-width: $size-S6) {
    .images-container {
      display: block;
      margin: 0;
    }

    .image-container,
    .video-element {
      padding: 0;
      width: auto;
      margin-top: 30px;

      &:first-child {
        margin-top: 0;
      }
    }

  }

}
