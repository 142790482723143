//
// CE Header Only CSS
//

header.ce-header-only {

  padding: 20px 0;

  .inner {
    margin: auto;
    max-width: $maxPageWidth;
    padding: 0 20px;
  }

  h1 {
    color: $white;
  }

  h2 {
    color: $white;
    @include font-size(24);
  }

}


// colored header
.header-secondary-text header.ce-header-only {
  background-color: $area-adminstration;
}
.citylife .header-secondary-text header.ce-header-only {
  background-color: $area-citylife;
}
.tourism .header-secondary-text header.ce-header-only {
  background-color: $area-tourism;
}
.commerce .header-secondary-text header.ce-header-only {
  background-color: $area-commerce;
}
.culture .header-secondary-text header.ce-header-only {
  background-color: $area-culture;
}