//
// Tourism Map CSS
//

.tourism-map {
  position: relative;

  img {
    cursor: pointer;
    opacity: .3;
  }

  .tourism-map-info-layer {
    position: absolute;
    top: 50%;
    left: 50%;
    background: $white;
    width: 100%;
    max-width: 400px;
    transform: translate(-50%, -50%);
    padding: 30px;
    border: 1px solid $red;
    pointer-events: none;
  }
}


#city-library-tourism {
  max-width: 1160px;
  margin: auto;
  display: block;
  border: none;
}