//
// CE Text Simple CSS
//

.ce-text-simple {
  padding: 20px 0;

  .ce-inner {
    padding: 0 20px;
  }

  &.layout-20,
  &.layout-30 {
    background-color: #e40421;
    color: $white;
    text-align: center;
    padding: 100px 0;
    margin: 20px 0;

    .ce-inner {
      max-width: 900px;
    }

    h1,
    h2,
    h3 {
      color: $white;
    }

    a {
      color: #ffed00;
    }

    @media screen and (max-width: $size-L) {
      padding: 40px 0;
    }
  }

  &.layout-30 {
    background-color: #005daa;
  }

  &.layout-40 {
    max-width: $maxPageWidth;
    padding:  0 20px;
    margin-left: auto;
    margin-right: auto;

    .ce-inner {
      border: 10px solid $black;
      text-align: center;
      padding: 30px 60px;

      @media screen and (max-width: $size-L) {
        padding: 20px 30px;
      }
    }
  }

}
