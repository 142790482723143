//
// News & Events CSS
//

section.news-teaser {
  position: relative;

  padding: 20px 0 0 0;

  header {
    padding: 0 20px;
  }

  ul {
    @include ul-reset;
    @include clearfix;
    margin: -20px;
    display: flex;
    flex-flow: row wrap;
  }

  .news-teaser-item {
    width: calc(100% / 3);
    padding: 20px;
    display: flex;
    flex-flow: column;
  }

  .news-image {
    img {
      display: block;
      width: 100%;
    }
  }

  .news-teaser-text {
    padding: 3px 10px 10px 10px;
    background: $black;
    color:  $grey-light;
    flex: 1;

    h3 {
      margin: 0;
      @include font-size(18);
    }

    p {
      margin: 0;
    }
  }

  .more-link {
    margin-top: 20px;
    text-align: right;

    a {
      display: inline-block;
      padding: 2px 5px;
      border: 1px solid $grey;
      color: $grey;
      text-transform: uppercase;
      text-decoration: none;
      background: $white;
    }

  }

  .show-all-link {
    position: absolute;
    display: none;
    right: 0;
    top: -45px;
    text-align: right;

    a {
      display: inline-block;
      background: url("../Svgs/arrow_red.svg") no-repeat right center;
      padding: 0 20px 0 0;
      background-size: 20px 20px;
    }
  }


  @media screen and (max-width: $size-L4) {

    header {
      padding: 0 10px;
    }

    ul {
      margin: -10px;
    }

    .news-teaser-item {
      padding: 10px;
    }

  }


  @media screen and (max-width: $size-M2) {
    .show-all-link {
      position: relative;
      right: auto;
      top: auto;
      padding: 30px 0;
    }
  }


  @media screen and (max-width: $size-S6) {
    ul {
      margin: 0;
      display: block;
    }

    .news-teaser-item {
      padding: 0;
      width: auto;

      margin-top: 30px;

      &:first-child {
        margin-top: 0;
      }
    }

  }

}

// show all link on start page only
.page-id-1 {
  section.news-teaser .show-all-link {
    display: block;
  }
}


// News Details

.citylife .news-detail .header-wrap {
  background-color: $area-citylife;
}

.culture .news-detail > .header-wrap {
  background-color: $area-culture;
}


.news-detail {

  .header-wrap {
    background: $red_base;
  }

  header {
    max-width: $maxPageWidth;
    margin: 0 auto 40px auto;
    color: $white;
    padding: 0 20px 20px 20px;

    h1 {
      color: $white;
      margin-bottom: 5px;
    }
  }


  .news-detail-body {
    @include clearfix;

    .news-img-wrap {
      float: left;
      width: 50%;
      padding-right: 15px;
    }

    .news-text-wrap {
      float: left;
      width: 50%;
      padding-left: 15px;
    }

    @media screen and (max-width: $size-L) {
      .news-img-wrap {
        float: none;
        width: auto;
        padding-right: 0;
      }

      .news-text-wrap {
        float: none;
        width: auto;
        padding-left: 0;
        margin-top: 20px;
      }
    }
  }



  .social {
    padding-top: 40px;

    > div {
      margin-bottom: 10px;
    }
  }
}