//
// Social Media CSS
//

.social-media {
  float: right;
  border-left: 1px solid $white;
  padding: 20px 0 50px 70px;

  ul {
    @include ul-reset;
    @include clearfix;

    li {
      float: left;
      margin-left: 10px;

      &:first-child {
        margin: 0;
      }
    }
  }


  svg {
    width: 32px;
    height: 32px;
  }


  @media screen and (max-width: $size-M3) {
    padding-left: 30px;
  }

  @media screen and (max-width: $size-S3) {
    float: none;
    border: none;
    padding: 10px 0 0 0;
  }

}