//
// Header CSS
//

.page > header {
  position: relative;
  z-index: 12;
  top: 0;
  left: 0;
  right: 0;
  background: url("../Svgs/wave_red_w_shaddow.svg") no-repeat;
  background-size: 100%;
  height: 240px;

  .inner {
    margin: auto;
    max-width: $maxPageWidth;
    padding: 0 20px;
  }

  .logo {
    float: right;
    margin: 30px 30px 0 0;
  }

  .menu {
    float: left;
    position: relative;
  }

  h1 {
    color: $white
  }

  h2 {
    color: $white;
  }

  @media screen and (min-width: $size-HD) {
    height: 260px;
  }

  @media screen and (max-width: $size-XXXXL) {
    height: 200px;

    .logo {
      margin-right: 130px;
    }
  }

  @media screen and (max-width: $size-XL) {
    height: 150px;

    .logo {
      margin-top: 20px;
    }
  }

  @media screen and (max-width: $size-L3) {
    height: 100px;

    .logo {
      width: 200px;
    }
  }

  @media screen and (max-width: $size-L) {
    .logo {
      margin: 15px 70px 0 0;
    }
  }

  @media screen and (max-width: $size-L) {
    background: $red_base !important;
    height: 60px;
    .logo {
      margin: 8px 25px 0 0;
      width: 170px;
    }
    .inner {
      padding: 0;
    }
  }

  @media screen and (max-width: $size-XXXS) {
    .logo {
      margin: 10px 15px 0 0;
      width: 150px;
    }
  }

}

// start page
.page-id-1 .page > header {
  position: absolute;

  @media screen and (max-width: $size-XS) {
    position: relative;
  }
}

// colored header
.page > header {
  background-color: $area-adminstration;
}

// start page no color
.page-id-1 .page > header {
  background-color: transparent;
}

.citylife .page > header {
  background-color: $area-citylife;
}
.tourism .page > header {
  background-color: $area-tourism;
}
.commerce .page > header {
  background-color: $area-commerce;
}
.culture .page > header {
  background-color: $area-culture;
}