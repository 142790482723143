//
// CE Spacing CSS
//

main {

  .ce-space-before-extra-small {
    margin-top: 10px !important;
  }

  .ce-space-before-small {
    margin-top: 20px !important;
  }

  .ce-space-before-medium {
    margin-top: 40px !important;
  }

  .ce-space-before-large {
    margin-top: 60px !important;
  }

  .ce-space-before-extra-large {
    margin-top: 80px !important;
  }

  .ce-space-after-extra-small {
    margin-bottom: 10px !important;
  }

  .ce-space-after-small {
    margin-bottom: 20px !important;
  }

  .ce-space-after-medium {
    margin-bottom: 40px !important;
  }

  .ce-space-after-large {
    margin-bottom: 60px !important;
  }

  .ce-space-after-extra-large {
    margin-bottom: 80px !important;
  }

}