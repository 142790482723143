//
// Defaul Project Settings
//

$maxPageWidth: 1200px;
$baseFont: "Open Sans", sans-serif;


//breakpoints
$size-HD: 1930px;
$size-XXXXL: 1460px;
//$size-XXL: 1300px;
$size-XL: 1100px;
$size-L4: 1000px;
$size-L3: 900px;
$size-L2: 850px;
$size-L: 800px;
$size-M3: 750px;
$size-M2: 700px;
//$size-M: 700px;
$size-S6: 650px;
//$size-S5: 600px;
$size-S4: 550px;
$size-S3: 500px;
$size-S: 450px;
$size-XS: 400px;
//$size-XXS: 380px;
$size-XXXS: 360px;