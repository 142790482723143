//
// Header Secondary CSS
//

.header-secondary {
  position: relative;

  .ce-hero-image {
    img {
      display: block;
      max-width: none;
      width: 100%;
    }
  }
}