//
// Search CSS
//

//
// EXT: Indexed Search CSS - new fluid base search
//

// keep seperation to top search box
main {

  .tx-indexedsearch-searchbox {
    margin-bottom: 20px;

    fieldset {
      border: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
    }

    legend {
      display: none;
    }

  }

  .tx-indexedsearch-info {
    line-height: 1;

    dt {
      display: inline-block;
    }

    dd {
      display: inline-block;
      margin: 0 10px 0 0;
    }

  }

  .tx-indexedsearch-form {
    float: left;
  }

  .tx-indexedsearch-search-submit {
    float: left;
  }

  .tx-indexedsearch-searchbox-sword {
    border: 1px solid $grey-mid;
    padding: 3px 10px;
  }

  .tx-indexedsearch-searchbox-button {
    border: none;
    background: $red;
    color: $white;
    cursor: pointer;
    padding: 4px 20px;
  }


  .tx-indexedsearch-res {

    h3 {
      margin: 15px 0 5px 0;
    }

    p {
      margin: 0;
    }

  }

  .tx-indexedsearch-browsebox {

    ul {
      @include ul-reset;
      display: flex;

      li {
        padding-left: 10px;

        &:first-child {
          padding-left: 0;
        }
      }
    }

  }

  .inner {

    > .tx-indexedsearch-browsebox {
      &:last-of-type {
        padding-bottom: 20px;
      }
    }

  }

}