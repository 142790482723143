//
// Project Color Definitions
//

$grey-dark: #282828;
$grey: #393e42;
$grey-mid: #6b6b6b;
$grey-light: #f9f7f6;
$blue-gradient-start: #65a2cf;
$blue-gradient-stop: #dbe6f4;
$red: #af3b38;
$red_base: #A61E32;
$white: #FFFFFF;
$black: #000000;

$area-adminstration: #A21F33;
$area-citylife: #045492;
$area-tourism: #339966;
$area-commerce: #2E3E51;
$area-culture: #CB9A03;