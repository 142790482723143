//
// Main CSS
//

main {

  hr {
    border: none;
    max-width: 1160px;
    margin: auto;
    height: 1px;
    background-color: $grey;
  }

  .ce-inner {
    margin: auto;
    max-width: $maxPageWidth;
  }

  .bodytext {
    ul {
      padding-left: 15px;
    }
  }

}