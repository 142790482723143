//
// Import Base CSS and add custom
//

// Base Settings
@import "base/settings";
@import "base/colors";

// Mixins
@import "base/mixins";

// Normalize
@import "base/normalize";

// Add global CSS
@import "base/global";

@import "base/fonts";

// Add Magnific Popup
@import "lib/magnific_popup/main";


//
// CUSTOM CSS
//

// general
body {
  font-family: $baseFont;
  @include font-size(14);
}

h1 {
  @include font-size(32);
  font-weight: 300;
  margin: 0 0 15px 0;
  color: $grey-mid;
}

h2 {
  @include font-size(32);
  font-weight: 300;
  margin: 0 0 15px 0;
  color: $grey-mid;
}

h3 {
  @include font-size(22);
  font-weight: 300;
  margin: 0 0 15px 0;
}

a {
  text-decoration: none;
  color: $red;
}

@media screen and (max-width: $size-S6) {
  h1,
  h2 {
    @include font-size(26);
  }
}


@import "header";
@import "header-secondary";
@import "nav-top";
@import "nav-main";
@import "nav-areas";
@import "lib/slick_slider/base";

@import "main";

@import "footer";
@import "social-media";
@import "burger-icon";
@import "search-box";
@import "search";

@import "ce-spacing";

@import "ce-header-only";
@import "ce-text-media";
@import "ce-hero-image";
@import "ce-image-grid3";
@import "ce-text-simple";
@import "ce-contact-person";
@import "ce-plugin";

@import "tourism-map";
@import "news";
@import "companies";

@import "cookie-consent";